import React from "react"
import Helmet from 'react-helmet'
// Components
import { Link } from "gatsby"
import Layout from '../components/layout'

class ArchivesPage extends React.Component {
    render() {
        const { group, index, first, last, pageCount,pathPrefix } = this.props.pageContext;
        const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
        const nextUrl = (index + 1).toString();

        const NavLink = props => {
            if (!props.test) {
            return <Link to={props.url}>{props.text}</Link>;
            } else {
            return "";
            }
        }

      return (
        <Layout path={this.props.location.pathname}>
            <Helmet>
            <title>{` Archive Page ${index}/${pageCount}`}</title>
            </Helmet>
            <div>
            {group.map(({ node }) => (
              <div key={node.id}>
                <Link
                  to={node.fields.slug}>
                  <h3>
                    {node.title}
                  </h3>
                </Link>
              </div>
            ))}
            </div>
            <ul className="navlink" >
            <li className="previousLink">
              <NavLink test={first} url={`${pathPrefix}/${previousUrl}`} text="Newer" />
            </li>
            <li className="nextLink">
              <NavLink test={last} url={`${pathPrefix}/${nextUrl}`} text="Older" />
            </li>
            </ul>
        </Layout>
      )
    }
}
export default ArchivesPage
